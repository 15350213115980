/* eslint-disable @typescript-eslint/camelcase */
// PRN Label
import { ThermalReceiptData } from "@/domain/entities/Printer";
import { PrintDataEntitas } from "@/domain/entities/Booking";
import dateToDateString from "@/app/infrastructures/misc/common-library/DateToDateString";
import ellipsisString from "@/app/infrastructures/misc/common-library/EllipsisString";
import removeDecimalWithoutRounding from "@/app/infrastructures/misc/common-library/RemoveDecimalWithoutRounding";
import {
  abbreviationVendorCode,
  estimationBound,
  vendorPickupDetail,
  isCourierRecommendation,
  maskPhone,
  badWordsMasking,
  isShipmentC1Cod,
  isShipmentFavoritCOD,
  codDfodLabel,
  formatPrice
} from "@/app/ui/modules/receipt-v2/module";
import {
  COD_BACKGROUND_WATERMARK,
  ONEPACK_BACKGROUND_WATERMARK,
  DFOD_BACKGROUND_WATERMARK
} from "@/app/ui/assets/string/thermal";
import moment from "moment";
import startCase from "lodash/startCase";
import { createI18n } from "vue-i18n";
import en from "@/i18n/locales/en";
import id from "@/i18n/locales/id";
import my from "@/i18n/locales/my";
import { FlagsFeatureShipmentC1 } from "@/feature-flags/flags-shipment-c1";

export const newFormatTextPrn = (
  params: ThermalReceiptData,
  language = "id"
) => {
  const i18nInstance = createI18n({
    legacy: false,
    locale: language,
    fallbackLocale: "id",
    globalInjection: false,
    messages: {
      en,
      id,
      my
    }
  });
  const translate = i18nInstance.global.t;

  const commodity =
    language === "en" ? params.sttCommodityDescription : params.sttCommodity;

  let prnString = "^XA\n" + "^MMT\n" + "^PW799\n" + "^LL1199\n" + "^LS0\n\n";

  if (params.sttProduct === "ONEPACK") {
    // add background onepack
    prnString += ONEPACK_BACKGROUND_WATERMARK;
  }

  const isCod = codDfodLabel(params.sttIsCod, params.sttIsDfod) === "cod";
  const isDfod = codDfodLabel(params.sttIsCod, params.sttIsDfod) === "dfod";

  // logo
  prnString +=
    "^FX SECTION LOGO LP\n" +
    `^FO0,0^GFA,02688,02688,00028,:Z64:\n` +
    `${params.sttLionParcelImage}=:E160\n\n`;
  prnString += `^FX LOGO LIONPARCEL PENERIMA^FO35,960${params.sttLionParcelImageSmall}^FS\n\n`;

  // line
  prnString += `^FX SECTION FRIST HORIZONTAL LINE\n^FO0,85^GB810,2,2^FS`;
  prnString += `^FX SECTION SECOND HORIZONTAL LINE^FO0,700^GB810,2,2^FS\n\n`;
  prnString += `^FX SECTION THIRD DASHED LINE\n`;
  for (let i = 0; i <= 810; i += 10) {
    prnString += `^FO${i},915^GB7,2,2^FS\n`;
  }
  prnString += `^FO25,917${params.sttScissorsImage}^FS^FT45,930^A0N,12^FH\\^FD${translate("receipt.senderPage")}^FS\n`;

  // 3lc origin to destination | Ursa Code
  prnString +=
    `^FX SECTION ORIGIN 3LC to DESTINATION 3LC\n` +
    `^FT780,65,1^A0N,40^FH\\^FD\n` +
    `${params.sttOrigin3lc} - ${params.sttDestination3lc}${
      params.sttUrsaCode ? " | " + params.sttUrsaCode : ""
    }^FS\n\n`;

  // stt number
  prnString +=
    `^FX SECTION STT Number & Barcode\n` +
    `^FT25,120^A0N,30^FH\\^FD\n` +
    `${params.sttNumber}^FS\n` +
    `^BY2,3,100\n` +
    `^FT25,230^BCN,,N,N\n^FD>:${params.sttNumber}^FS\n\n`;
  prnString += `^BY1,3,60^FT410,1010,1^BCN,,Y,N^FD>:${params.sttNumber}^FS`;

  // district destination
  prnString +=
    `^FX SECTION DISTRICT DESTINATION\n` +
    `^FT780,137,1^A0N,22^FB500,2,5,R^FH\\^FD\n` +
    `${params.sttDistrictDestination}^FS\n\n`;

  // stt date & sla
  prnString +=
    `^FX SECTION STT DATE & SLA\n` +
    `^FT780,160,1^A0N,18^FH\\^FD\n` +
    `${startCase(translate("receipt.createdAt"))} :^FS\n` +
    `^FT780,180,1^A0N,18^FH\\^FD\n` +
    `${dateToDateString(params.sttDate, true, false, language)}^FS\n` +
    `^FT780,210,1^A0N,18^FH\\^FD\n` +
    `${startCase(translate("receipt.estimation"))} :^FS\n` +
    `^FT780,230,1^A0N,18^FH\\^FD\n` +
    `${dateToDateString(
      moment(params.sttSla, "DD MMMM YYYY"),
      true,
      false,
      language
    )}^FS\n\n`;

  // Box Pengirim & Penerima
  prnString += `^FX BOX SECTION\n` + `^FT25,535^GB750,285,3,B,1^FS\n\n`;
  prnString += `^FT25,1180^GB750,245,2,B,1^FS\n`;
  prnString += `^FO25,1035^GB400,2,2^FS\n^FT425,1150^GB2,215,2^FS\n^FT625,1180^GB2,245,2^FS\n^FT625,1090^GB150,2,2^FS\n^FO25,1035^GB400,2,2^FS\n`;

  // pengirim
  prnString +=
    `^FX SECTION PENGIRIM\n` +
    `^FT40,285^A0N,20^FH\\^FD${startCase(
      translate("receipt.shipper")
    )}^FS\n^FT130,285^A0N,20^FH\\^FD:^FS\n` +
    `^FB580,2,5,L^FT150,308^A0N,20^FH\\^FD${params.sttSenderFormattedName}^FS\n`;
  // penerima
  prnString +=
    `^FX SECTION PENERIMA\n` +
    `^FT40,340^A0N,20^FH\\^FD${startCase(
      translate("receipt.receiver")
    )}^FS\n^FT130,340^A0N,20^FH\\^FD:^FS\n` +
    `^FB580,2,5,L^FT150,363^A0N,20^FH\\^FD${params.sttRecipientFormattedName}^FS\n` +
    `^FB580,4,5,L^FT150,465^A0N,20^FH\\^FD${params.sttRecipientAddress}^FS\n`;
  // label kantor penerima
  if (params.sttDestinationAddressType === "office") {
    prnString += `^FX SECTION LABEL KANTOR\n^FO40,380^GB80,40,40, B, 2^FS^FT40,405^FR^A0N,18^FB80,1,0,C^FH^FD${startCase(
      translate("receipt.office")
    )}\\&^FS\n\n`;
  }
  // commodity
  prnString += `^FX SECTION COMMODITY\n^FT40,515^FB450,2,5,L^A0N,18^FH\\^FD${commodity}^FS\n\n`;

  let xLabelPoint = 675;

  // cod
  if (params.sttCodAmount !== "-") {
    prnString += `^FX SECTION ${isCod ? 'COD' : 'DFOD'}^CFA,15^FO${xLabelPoint},470^GB90,50,40,B,2^FS^FT${xLabelPoint},505^A0N,30^FR^FB90,1,0,C^FH^FD${isCod ? 'COD' : 'DFOD'}\\&^FS\n`;
    prnString += `^FT25,745^A0N,30^FH\\^FDCOD : ${params.sttCodAmount}^FS\n`;
    prnString += `^FO610,950,1^GB50,40,40,B,2^FS^FT610,977,1^A0N,18^FR^FB50,1,0,C^FH^FD${isCod ? 'COD' : 'DFOD'}\\&^FS\n`;
    prnString +=  isCod ? COD_BACKGROUND_WATERMARK : DFOD_BACKGROUND_WATERMARK;
    xLabelPoint -= 100;
  } else {
    prnString += `^FT25,745^A0N,30^FH\\^FDNON COD^FS\n`;
  }
  // do
  if (params.sttIsDo) {
    prnString += `^FX SECTION DO^CFA,15^FO${xLabelPoint},470^GB90,50,40,B,2^FS^FT${xLabelPoint},505^A0N,30^FR^FB90,1,0,C^FH^FDDO\\&^FS\n\n`;
    xLabelPoint -= 100;
  }
  // pad
  if (params.sttPadAmount !== "-" && params.sttProduct !== "INTERPACK") {
    prnString += `^FX SECTION PAD^CFA,15^FO${xLabelPoint},470^GB90,50,40,B,2^FS^FT${xLabelPoint},505^A0N,30^FR^FB90,1,0,C^FH^FDPAD\\&^FS\n`;
    prnString += `^FT25,785^A0N,30^FH\\^FDPAD : ${params.sttPadAmount}^FS\n`;
    xLabelPoint -= 100;
  }
  // GOOD VALUE FOR INTERPACK
  if (params.sttProduct === "INTERPACK") {
    if (params.sttBookedByCountry === "MY") {
      prnString += `^FT25,785^A0N,30^FH\\^FD${startCase(
        translate("receipt.goodValue")
      )}  ${formatPrice(params.sttBookedByCountry, params.sttGoodsEstimatePrice)}^FS\n`;
    } else {
      prnString += `^FT25,785^A0N,30^FH\\^FD${startCase(
        translate("receipt.goodValue")
      )}  ${translate("receipt.currencyDisplayGoodValue", {
        value: removeDecimalWithoutRounding(params.sttGoodsEstimatePrice)
      })}^FS\n`;
    }
    
    xLabelPoint -= 100;
  }
  // RTS
  if (params.sttIsRts) {
    prnString += `^FX SECTION RTS^CFA,15^FO${xLabelPoint},470^GB90,50,40,B,2^FS^FT${xLabelPoint},505^A0N,30^FR^FB90,1,0,C^FH^FDRTS\\&^FS\n\n`;
    xLabelPoint -= 100;
  }
  // REROUTE from MISBOOKING
  if (params.sttIsRerouteMisbooking) {
    prnString += `^FX SECTION RB^CFA,15^FO${xLabelPoint},470^GB90,50,40,B,2^FS^FT${xLabelPoint},505^A0N,30^FR^FB90,1,0,C^FH^FDRB\\&^FS\n\n`;
    xLabelPoint -= 100;
  }
  // REROUTE from MISROUTE
  if (params.sttIsRerouteMisroute) {
    prnString += `^FX SECTION RR^CFA,15^FO${xLabelPoint},470^GB90,50,40,B,2^FS^FT${xLabelPoint},505^A0N,30^FR^FB90,1,0,C^FH^FDRR\\&^FS\n\n`;
    xLabelPoint -= 100;
  }

  // barcode vendor
  if (params.sttVendorNumber) {
    prnString += `^FX SECTION BARCODE_VENDOR^BY2,3,75^FT25,620^BCN,,Y,N^FD>:${params.sttVendorNumber}^FS\n`;
  }
  // woodpacking
  if (params.sttIsWoodpacking) {
    let xWoodpacking = 350;
    if (!params.sttVendorCode) {
      xWoodpacking += 95;
    }
    if (!params.isDangerousGoods) {
      xWoodpacking += 85;
    }
    if (!params.sttIsQuarantine) {
      xWoodpacking += 85;
    }
    if (!params.isReverseJourneyAfterCancel) {
      xWoodpacking += 85;
    }
    prnString += `^FX WOODPACKING CODE^CFA,15^FO${xWoodpacking},545^GB85,60,40,B,2^FS^FT${xWoodpacking + 5},585^A0N,30^FR^FB75,1,0,C^FH^FDKAYU\\&^FS\n`;
  }
  // vendor code
  if (params.sttVendorCode) {
    let xVendorCode = 445;
    if (!params.isDangerousGoods) {
      xVendorCode += 85;
    }
    if (!params.sttIsQuarantine) {
      xVendorCode += 85;
    }
    if (!params.isReverseJourneyAfterCancel) {
      xVendorCode += 85;
    }
    prnString += `^FX SECTION VENDOR CODE^CFA,15^FO${xVendorCode},545^GB75,60,40,B,2^FS^FT${xVendorCode},585^A0N,30^FR^FB75,1,0,C^FH^FD${params.sttVendorCode}\\&^FS\n`;
  }
  // cnx
  if (params.isReverseJourneyAfterCancel) {
    let CNX = 530;
    if (!params.isDangerousGoods) {
      CNX += 85;
    }
    if (!params.sttIsQuarantine) {
      CNX += 85;
    }
    prnString += `^FX SECTION VENDOR CODE^CFA,15^FO${CNX},545^GB75,60,40,B,2^FS^FT${CNX},585^A0N,30^FR^FB75,1,0,C^FH^FDCNX\\&^FS\n`;
  }
  // dangerous goods
  if (params.isDangerousGoods) {
    let xDG = 615;
    if (!params.sttIsQuarantine) {
      xDG += 85;
    }
    prnString += `^FX SECTION DANGEROUS GOODS (DG)^CFA,15^FO${xDG},545${params.sttDangerousGoodsImage}^FS\n`;
  }
  // quarantine goods
  if (params.sttIsQuarantine) {
    prnString += `^FX SECTION KARANTINA^CFA,15^FO700,545${params.sttQuarantineImage}^FS\n\n`;
  }
  // booked by
  prnString += `^FX SECTION BOOKED BY^FT25,690^FB300,2,0,L^A0N,22^FH\\^FD${params.sttBookedBy}^FS\n`;
  // external ref
  prnString += `^FX SECTION EXTERNAL NUMBER^FT780,690,1^A0N,22^FH\\^FDExt. Ref : ${params.sttExternalRefNumber}^FS\n\n`;

  // label product
  prnString += `^FX SECTION PRODUCT^CFA,15^FO610,715,1^GB170,60,40, B, 2^FS^FT610,758,1^FR^A0N,30^FB170,1,0,C^FH^FD${params.sttProduct}\\&^FS\n`;
  prnString += `^FO435,950^GB100,40,40, B, 2^FS^FT435,977^FR^A0N,18^FB100,1,0,C^FH^FD${params.sttProduct}\\&^FS\n`;

  // jumbopack h2h
 if(params.isHubToHub) {
  prnString += `^FX SECTION IS HUB TO HUB^FT449,818^FB300,2,0,L^A0N,22^FH\\^FDDiambil penerima\\^FS\n`;
 }
  // qr code
  prnString += `^FX SECTION QR CODE^FT795,1105,1^BQN,2,4,H,7^FDQA,${params.sttQrCode}^FS\n`;

  // copy penerima
  prnString += `^FT40,1060^A0N,16^FH\\^FD${startCase(
    translate("receipt.shipper")
  )}^FS\n^FT40,1085^A0N,16^FH\\^FD${startCase(
    translate("receipt.receiver")
  )}^FS\n^FT40,1110^A0N,16^FH\\^FD${startCase(
    translate("receipt.destination")
  )}^FS\n^FT40,1135^A0N,16^FH\\^FD${startCase(
    translate("receipt.estimation")
  )}^FS\n`;
  prnString += `^FT130,1060^A0N,16^FH\\^FD: ${ellipsisString(
    params.sttSenderName,
    33
  )}^FS\n`;
  prnString += `^FT130,1085^A0N,16^FH\\^FD: ${ellipsisString(
    params.sttRecipientName,
    33
  )}^FS\n`;
  prnString += `^FT130,1110^A0N,16^FH\\^FD: ${ellipsisString(
    params.sttDistrictDestination,
    30
  )}^FS\n`;

  // copy footer lion parcel
  prnString += `^FX SECTION COPY LION PARCEL^FT25,905^A0N,15^FH\\^FD${translate(
    "receipt.downloadApps"
  )}^FS^FT780,905,1^A0N,15^FH\\^FD${translate("receipt.toc")}^FS\n`;
  prnString += `^FT130,1135^A0N,16^FH\\^FD: ${dateToDateString(
    moment(params.sttSla, "DD MMM YYYY"),
    true,
    false,
    language
  )}^FS\n`;
  prnString += `^FT40,1165^A0N,11^FH\\^FD${translate(
    "receipt.downloadApps"
  )}^FS^FT615,1165,1^A0N,11^FH\\^FD${translate("receipt.toc")}^FS\n\n`;

  // rincian biaya
  const showCodDetail =
    params.sttIsCodRetail || params.sttShipmentId.match(/^ARA|^ARB/gi) || isShipmentC1Cod(params.sttShipmentId, params.sttIsCod)
    || isShipmentFavoritCOD(params.sttShipmentId, params.sttIsCod) || params.sttIsDfod;
  const y = showCodDetail ? 810 : 820;
  let currentYDetailAmount = y;
  const gapYDetailAmount = showCodDetail ? 20 : 25;
  prnString +=
    `^FT25,${currentYDetailAmount}^A0N,16^FH\\^FD${startCase(
      translate("receipt.totalCost")
    )}^FS\n^FT117,${currentYDetailAmount}^A0N,16^FH\\^FD:^FS\n` +
    `^FT130,${currentYDetailAmount}^A0N,16^FH\\^FD${
      showCodDetail ? params.totalTariffCodRetail : params.sttTotalPrice
    }^FS\n`;
  currentYDetailAmount += gapYDetailAmount;

  prnString +=
    `^FT25,${currentYDetailAmount}^A0N,16^FH\\^FD${startCase(
      translate("receipt.shippingCost")
    )}^FS\n^FT117,${currentYDetailAmount}^A0N,16^FH\\^FD:^FS\n` +
    `^FT130,${currentYDetailAmount}^A0N,16^FH\\^FD${
        params.sttShippingCost
    }^FS\n`;
  currentYDetailAmount += gapYDetailAmount;

  prnString +=
    `^FT25,${currentYDetailAmount}^A0N,16^FH\\^FD${startCase(
      translate("receipt.insurance")
    )}^FS\n^FT117,${currentYDetailAmount}^A0N,16^FH\\^FD:^FS\n` +
    `^FT130,${currentYDetailAmount}^A0N,16^FH\\^FD${params.sttInsuranceRate}^FS\n`;
  currentYDetailAmount += gapYDetailAmount;

  if (showCodDetail) {
    prnString +=
      `^FT25,${currentYDetailAmount}^A0N,16^FH\\^FD${isDfod ? startCase(
        translate("receipt.dfodFee")
      ) : startCase(
        translate("receipt.goodsPrice")
      )}^FS\n^FT117,${currentYDetailAmount}^A0N,16^FH\\^FD:^FS\n` +
      `^FT130,${currentYDetailAmount}^A0N,16^FH\\^FD${isDfod ? formatPrice(params.sttBookedByCountry, params.sttCodFee) : formatPrice(
        params.sttBookedByCountry,
        params.sttGoodsPrice
      )}^FS\n`;
      currentYDetailAmount += gapYDetailAmount;
  }

  currentYDetailAmount = y;
  prnString +=
    `^FT240,${currentYDetailAmount}^A0N,16^FH\\^FD${startCase(
      translate("receipt.totalWeight")
    )}^FS\n^FT325,${currentYDetailAmount}^A0N,16^FH\\^FD:^FS\n` +
    `^FT340,${currentYDetailAmount}^A0N,16^FH\\^FD${params.sttTotalWeight}^FS\n`;
  currentYDetailAmount += gapYDetailAmount;
  prnString +=
    `^FT240,${currentYDetailAmount}^A0N,16^FH\\^FD${translate(
      "receipt.taxBm"
    )}^FS\n^FT325,${currentYDetailAmount}^A0N,16^FH\\^FD:^FS\n` +
    `^FT340,${currentYDetailAmount}^A0N,16^FH\\^FD${params.sttTotalGoodsTax}^FS\n`;
  currentYDetailAmount += gapYDetailAmount;
  prnString +=
    `^FT240,${currentYDetailAmount}^A0N,16^FH\\^FD${startCase(
      translate("receipt.others")
    )}^FS\n^FT325,${currentYDetailAmount}^A0N,16^FH\\^FD:^FS\n` +
    `^FT340,${currentYDetailAmount}^A0N,16^FH\\^FD${params.sttSurchargeRate}^FS\n`;
  currentYDetailAmount += gapYDetailAmount;

  // rincian biaya footer
  currentYDetailAmount = showCodDetail ? 1010 : 1025;
  const fontRincianBiayaFooter = showCodDetail ? 14 : 16;
  const gapYDetailAmountFooter = 17;
  prnString += `^FT435,${currentYDetailAmount}^A0N,14^FH\\^FD${dateToDateString(
    params.sttDate,
    true,
    false,
    language
  )}^FS\n`;
  currentYDetailAmount += gapYDetailAmountFooter;
  currentYDetailAmount = showCodDetail ? currentYDetailAmount : 1060;

  prnString += `^FT435,${currentYDetailAmount}^A0N,${fontRincianBiayaFooter}^FH\\^FD${startCase(
    translate("receipt.totalWeight")
  )}^FS\n^FT525,${currentYDetailAmount}^A0N,{fontRincianBiayaFooter}^FH\\^FD: ${
    params.sttTotalWeight
  }^FS\n`;
  currentYDetailAmount += gapYDetailAmountFooter;

  if (params.isHubToHub) {
    prnString += "^FT435,1005^A0N,14^FH\\^FDDiambil penerima^FS";
  }

  prnString += `^FT435,${currentYDetailAmount}^A0N,{fontRincianBiayaFooter}^FH\\^FD${startCase(
    translate("receipt.shippingCost")
  )}^FS\n^FT525,${currentYDetailAmount}^A0N,{fontRincianBiayaFooter}^FH\\^FD: ${
     params.sttShippingCost
  }^FS\n`;
  currentYDetailAmount += gapYDetailAmountFooter;

  prnString += `^FT435,${currentYDetailAmount}^A0N,{fontRincianBiayaFooter}^FH\\^FD${startCase(
    translate("receipt.insurance")
  )}^FS\n^FT525,${currentYDetailAmount}^A0N,{fontRincianBiayaFooter}^FH\\^FD: ${
    params.sttInsuranceRate
  }^FS\n`;
  currentYDetailAmount += gapYDetailAmountFooter;

  prnString += `^FT435,${currentYDetailAmount}^A0N,{fontRincianBiayaFooter}^FH\\^FD${translate(
    "receipt.taxBm"
  )}^FS\n^FT525,${currentYDetailAmount}^A0N,{fontRincianBiayaFooter}^FH\\^FD: ${
    params.sttTotalGoodsTax
  }^FS\n`;
  currentYDetailAmount += gapYDetailAmountFooter;

  prnString += `^FT435,${currentYDetailAmount}^A0N,{fontRincianBiayaFooter}^FH\\^FD${startCase(
    translate("receipt.others")
  )}^FS\n^FT525,${currentYDetailAmount}^A0N,{fontRincianBiayaFooter}^FH\\^FD: ${
    params.sttSurchargeRate
  }^FS\n`;

  if (showCodDetail && isCod) {
    currentYDetailAmount += gapYDetailAmountFooter;
    prnString += `^FT435,${currentYDetailAmount}^A0N,{fontRincianBiayaFooter}^FH\\^FD${startCase(
      translate("receipt.goodsPrice")
    )}^FS\n^FT525,${currentYDetailAmount}^A0N,{fontRincianBiayaFooter}^FH\\^FD: ${formatPrice(
      params.sttBookedByCountry,
      params.sttGoodsPrice
    )}^FS\n`;
  }

  if (showCodDetail) {
    currentYDetailAmount += gapYDetailAmountFooter;
    prnString += `^FT435,${currentYDetailAmount}^A0N,{fontRincianBiayaFooter}^FH\\^FD${startCase(
      translate("receipt.returnFee")
    )}^FS\n^FT525,${currentYDetailAmount}^A0N,{fontRincianBiayaFooter}^FH\\^FD: ${formatPrice(
      params.sttBookedByCountry,
      params.sttReturnFee
    )}^FS\n`;

    currentYDetailAmount += gapYDetailAmountFooter;
    prnString += `^FT435,${currentYDetailAmount}^A0N,{fontRincianBiayaFooter}^FH\\^FD${isDfod ? startCase(
      translate("receipt.dfodFee")
    ) : startCase(
      translate("receipt.codFee")
    )}^FS\n^FT525,${currentYDetailAmount}^A0N,{fontRincianBiayaFooter}^FH\\^FD: ${formatPrice(
      params.sttBookedByCountry,
      params.sttCodFee
    )}^FS\n`;
  }

  const returnCostCodDfod = params.sttIsDfod ? params.sttTotalReturnFeeCDfod : params.sttTotalReturnFeeCod;
  prnString += `^FT660,1115^A0N,18^FH\\^FD${showCodDetail ? startCase(translate("receipt.returnCost")) : startCase(
    translate("receipt.totalCost")
  )}^FS\n^FT630,1170^FB150,2,0,C^A0N,22^FH^FD${showCodDetail ? formatPrice(params.sttBookedByCountry, returnCostCodDfod) : params.sttTotalPrice}\\&^FS\n`;
  if (showCodDetail) {
    prnString += `^FT660,1170^A0N,15^FH\\^FD${translate("receipt.returStatement")}^FS\n`;
  }

  // detail weight
  prnString += `^FT780,770,1^A0N,70^FH\\^FD${params.sttPiece}^FS\n`;
  prnString += `^FT445,825^A0N,30^FH\\^FDGW :^FS^FT780,825,1^A0N,30^FH\\^FD${params.sttWeight}^FS\n`;

  if (params.sttIsBigpack || params.sttIsJumbopack) {
    prnString += `^FT445,870^A0N,30^FH\\^FDVW :^FS^FT780,870,1^A0N,30^FH\\^FD- / -^FS\n`;
  } else {
    prnString += `^FT445,870^A0N,30^FH\\^FDVW :^FS^FT${710 -
      params.sttPieceVolumeWeight.length * 10},870,1^A0N,18^FH\\^FD${
      params.sttDimensionWeight
    } Cm^FS^FT780,870,1^A0N,26^FH\\^FD/ ${params.sttPieceVolumeWeight} Kg^FS`;
  }
  // end
  prnString += "^PQ1,0,1,Y\n" + "^XZ\n\n";
  return prnString;
};

const printThermal = (params: ThermalReceiptData[], prnText: string) => {
  let fileName = "resi-thermal.prn";

  if (params.length === 1) {
    fileName = `resi-thermal-${params[0].sttNumber}.prn`;
  }
  const blob = new Blob([prnText]);
  if (navigator.msSaveBlob) {
    navigator.msSaveBlob(blob, fileName);
  } else {
    const link = document.createElement("a");
    if (link.download !== undefined) {
      const url = URL.createObjectURL(blob);
      link.setAttribute("href", url);
      link.setAttribute("download", fileName);
      link.style.visibility = "hidden";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  }
};

let selectedDevice: any = null;
const autoPrint = (
  params: ThermalReceiptData[],
  prnText: string,
  isBookInstan = false
) => {
  const encoder = new TextEncoder();
  selectedDevice
    .transferOut(1, encoder.encode(prnText))
    .then(() => {
      console.log("print success");
    })
    .catch(() => {
      if (!isBookInstan) printThermal(params, prnText);
    });
};

const printUSB = (
  params: ThermalReceiptData[],
  prnText: string,
  isBookInstan = false
) => {
  navigator.usb
    .getDevices()
    .then(devices => {
      if (devices.length > 0) {
        selectedDevice = devices[0];
        return selectedDevice
          .open()
          .then(() => selectedDevice.selectConfiguration(1))
          .then(() => selectedDevice.claimInterface(0));
      } else {
        navigator.usb
          .requestDevice({ filters: [] })
          .then(device => {
            selectedDevice = device;
            return device
              .open()
              .then(() => device.selectConfiguration(1))
              .then(() => device.claimInterface(0));
          })
          .then(() => {
            autoPrint(params, prnText, isBookInstan);
          })
          .catch(error => {
            console.log(error);
          });
      }
    })
    .then(() => {
      if (selectedDevice !== null) {
        autoPrint(params, prnText, isBookInstan);
      }
    })
    .catch(err => {
      console.log(err);
    });
};

export const downloadFilePrn = (
  params: ThermalReceiptData[],
  language = "id",
  onTheFlyLanguage = false
) => {
  let prnText = "";
  for (const param of params) {
    const useLanguage = onTheFlyLanguage
      ? param.sttProduct === "INTERPACK"
        ? "en"
        : "id"
      : language;
    prnText += newFormatTextPrn(param, useLanguage);
  }

  printThermal(params, prnText);
};

export const payloadOfThermalData = (
  sttData: PrintDataEntitas,
  accountType: string
) => {
  interface Tariff {
    totalTariff: string;
    totalRate: string;
    insuranceRate: string;
    surchargeRate: string;
    totalGoodsTax: string;
    totalTariffCodRetail: string;
  }

  const tariff: Tariff = {
    totalTariff: "-",
    totalRate: "-",
    insuranceRate: "-",
    surchargeRate: "-",
    totalGoodsTax: "-",
    totalTariffCodRetail: "-"
  };

  let ursaCode = "";
  let pickupDetail = "";
  const thermalPayload = [];
  for (const piece of sttData.sttPieces) {
    const bookingType =
      (sttData.sttShipmentId && sttData.sttClientId === 0) ||
      (sttData.sttShipmentId && sttData.sttClientId !== 0)
        ? "shipment"
        : !sttData.sttShipmentId && sttData.sttClientId === 0 || sttData.sttReverseJourneyShipmentId.match(/^ARA|^ARB|^AP|^AS/)
        ? "manual"
        : "client";

    let shipmentId = sttData.sttShipmentId ? sttData.sttShipmentId : "";
    let isPriceOfCOD = sttData.sttIsCod;
    if (sttData.isReverseJourney && (shipmentId.startsWith(FlagsFeatureShipmentC1.feature_shipment_c1.getValue()) || shipmentId.match(/^AP|^AS/gi))) {
      shipmentId = sttData.sttReverseJourneyShipmentId;
      isPriceOfCOD = sttData.sttReverseJourneyCodHandling;
    }

    let lainLain = 0;
    if (!estimationBound(shipmentId, bookingType, accountType, isPriceOfCOD)) {
      lainLain = sttData.sttIsPromo
        ?
          Number(sttData.sttWoodpackingAfterRateDiscount) +
          Number(sttData.sttCommoditySurchargeRateAfterDiscount) +
          Number(sttData.sttHeavyweightSurchargeRateAfterDiscount) +
          Number(sttData.sttDocumentSurchargeRateAfterDiscount)
        :
          Number(sttData.sttWoodpackingRate) +
          Number(sttData.sttCommoditySurchargeRate) +
          Number(sttData.sttHeavyweightSurchargeRate) +
          Number(sttData.sttDocumentSurchargeRate);

      tariff.totalTariff =
        Number(sttData.totalTariff) > 0
          ? formatPrice(sttData.sttBookedByCountry, Number(sttData.totalTariff))
          : "-";
      tariff.totalRate =
        Number(sttData.totalRate) > 0
          ? formatPrice(sttData.sttBookedByCountry, Number(sttData.totalRate))
          : "-";
      tariff.insuranceRate = formatPrice(sttData.sttBookedByCountry, Number(sttData.sttInsuranceRate));
      tariff.surchargeRate = lainLain ? formatPrice(sttData.sttBookedByCountry, lainLain) : "-";
      tariff.totalGoodsTax =
        Number(sttData.sttBmTaxRate) > 0 ||
        Number(sttData.sttPpnTaxRate) > 0 ||
        Number(sttData.sttPphTaxRate) > 0
          ? formatPrice(
              sttData.sttBookedByCountry,
              Number(sttData.sttBmTaxRate) +
                Number(sttData.sttPpnTaxRate) +
                Number(sttData.sttPphTaxRate)
            )
          : "-";
      tariff.totalTariffCodRetail = formatPrice(
        sttData.sttBookedByCountry,
        Number(sttData.totalTariffCodRetail - sttData.sttCodFee)
      );
    }

    ursaCode = `${abbreviationVendorCode(
      sttData.sttVendorName,
      sttData.sttDistrictType
    )} ${sttData.sttDistrictUrsaCode}`;
    pickupDetail = vendorPickupDetail(
      sttData.sttVendorName,
      sttData.sttDistrictType
    );

    const combineQRTextForRegular = `https://lionparcel.com/track/stt?q=${sttData.sttNo}`;
    const combineQRTextForCourirRecomendation = JSON.stringify({
      stt_no: sttData.sttNo,
      partner_name: sttData.sttBookedForName,
      booking_id: sttData.otherShipperTicketCode
    });

    let qrcodeValue = "";
    if (isCourierRecommendation(sttData.sttNoRefExternal)) {
      qrcodeValue = combineQRTextForCourirRecomendation;
    } else {
      qrcodeValue = combineQRTextForRegular;
    }

    const postalCode =
      sttData.sttProductType.toLocaleLowerCase() === "interpack"
        ? `, ${sttData.sttPostalCode}`
        : "";

    const isBigpackVolume = sttData.sttChargeableWeight <= 10 && sttData.sttProductType.toUpperCase() === "BIGPACK";
    const isJumbopackVolume = sttData.sttChargeableWeight <= 30 && sttData.sttProductType.toUpperCase() === "JUMBOPACK";

    const returnCostCod = Number(sttData.totalRate) + Number(sttData.sttInsuranceRate) + lainLain +  Number(sttData.sttReturnFee);
    const returnCostDfod =  Number(sttData.totalRate) + lainLain +  Number(sttData.sttReturnFee);
    thermalPayload.push(
      new ThermalReceiptData({
        sttShipmentId: sttData.sttShipmentId,
        sttProduct: sttData.sttProductType,
        sttUrsaCode: ursaCode,
        sttSla: sttData.estimateSla,
        sttDate: sttData.sttCreatedAt,
        sttNumber: sttData.sttElexysNo || sttData.sttNo,
        sttNumberBarcode: sttData.sttElexysNo || sttData.sttNo,
        sttDistrictDestination: sttData.sttDestinationDistrictName,
        sttOrigin3lc: sttData.sttOriginCityId,
        sttDestination3lc: sttData.sttDestinationCityId,
        sttTransitOrigin3lc: sttData.sttOriginCityTransit,
        sttTransitDestination3lc: sttData.sttDestinationCityTransit,
        sttSenderName: badWordsMasking(sttData.sttSenderName),
        sttSenderPhone: maskPhone(sttData.sttSenderPhone),
        sttSenderAddress: sttData.sttSenderAddress,
        sttRecipientName: sttData.sttRecipientName,
        sttRecipientPhone: sttData.sttRecipientPhone,
        sttRecipientAddress: `${sttData.sttRecipientAddress} ${postalCode}`,
        sttCommodity: sttData.sttCommodityName,
        sttBookedBy: sttData.sttBookedName,
        sttBarcodeVendor: sttData.sttVendorRefferenceNo,
        sttVendorNumber: sttData.sttVendorRefferenceNo,
        sttIsDo: sttData.sttIsDo,
        sttClient: sttData.sttShipmentSource,
        sttExternalRefNumber: sttData.sttNoRefExternal,
        sttQrCode: qrcodeValue,
        sttTotalPrice: tariff.totalTariff,
        sttIsCod: sttData.sttIsCod,
        sttIsDfod: sttData.sttIsDfod,
        sttCodAmount: sttData.sttIsCod
          ? formatPrice(sttData.sttBookedByCountry, sttData.sttCodAmount)
          : "-",
        sttShippingCost: tariff.totalRate,
        sttInsuranceRate: tariff.insuranceRate,
        sttSurchargeRate: tariff.surchargeRate,
        sttTotalGoodsTax: tariff.totalGoodsTax,
        sttBMRate:
          Number(sttData.sttBmTaxRate) > 0
            ? formatPrice(sttData.sttBookedByCountry, Number(sttData.sttBmTaxRate))
            : "-",
        sttPPNRate:
          Number(sttData.sttPpnTaxRate) > 0
            ? formatPrice(sttData.sttBookedByCountry, Number(sttData.sttPpnTaxRate))
            : "-",
        sttPPHRate:
          Number(sttData.sttPphTaxRate) > 0
            ? formatPrice(sttData.sttBookedByCountry, Number(sttData.sttPphTaxRate))
            : "-",
        sttPiece: piece.stt_piece_count,
        sttWeight: isBigpackVolume || isJumbopackVolume ? '- / -' : `${piece.stt_piece_weight.split("/")[0]} Kg / ${
          piece.stt_piece_weight.split("/")[1]
        } Kg`,
        sttDimensionWeight: `${removeDecimalWithoutRounding(
          piece.stt_piece_length,
          1
        )}/${removeDecimalWithoutRounding(
          piece.stt_piece_width,
          1
        )}/${removeDecimalWithoutRounding(piece.stt_piece_height, 1)}`,
        sttVendorPickupDetail: pickupDetail,
        sttVendorName: sttData.sttVendorName,
        sttOriginCityName: sttData.sttOriginCityName,
        isDangerousGoods: sttData.isDangerousGoods,
        sttTotalWeight: isBigpackVolume ? "Min. 10 Kg" : isJumbopackVolume ? "Min. 30 Kg" : sttData.sttChargeableWeight + " Kg",
        sttPieceVolumeWeight: removeDecimalWithoutRounding(
          piece.stt_piece_volume_weight,
          1
        ),
        sttDestinationAddressType: sttData.sttRecipientAddressType,
        sttIsRts: sttData.sttIsRts,
        sttIsRerouteMisbooking: sttData.sttIsRerouteMisbooking,
        sttIsRerouteMisroute: sttData.sttIsRerouteMisroute,
        sttGoodsEstimatePrice: sttData.sttGoodsEstimatePrice,
        sttCommodityDescription: sttData.sttCommodityDescription,
        isReverseJourneyAfterCancel: sttData.isReverseJourneyAfterCancel,
        sttGoodsPrice: sttData.sttGoodsPrice,
        sttReturnFee: sttData.sttReturnFee,
        sttCodFee: sttData.sttCodFee,
        totalTariffCodRetail: tariff.totalTariffCodRetail,
        sttIsBigpack: isBigpackVolume,
        sttIsJumbopack: isJumbopackVolume,
        sttTotalReturnFeeCod: returnCostCod,
        sttTotalReturnFeeCDfod: returnCostDfod,
        sttBookedByCountry: sttData.sttBookedByCountry,
        sttIsWoodpacking: sttData.sttIsWoodpacking
      })
    );
  }

  return thermalPayload;
};
